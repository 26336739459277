if ('define' in window) {
define("discourse/theme-5/discourse/templates/connectors/sidebar-footer-actions/toggler-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showInSidebar}}
    <ColorSchemeToggler />
  {{/if}}
  */
  {
    "id": null,
    "block": "[[[41,[30,0,[\"showInSidebar\"]],[[[1,\"  \"],[8,[39,1],null,null,null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"color-scheme-toggler\"]]",
    "moduleName": "discourse/theme-5/discourse/templates/connectors/sidebar-footer-actions/toggler-button",
    "isStrictMode": false
  });
});
}
