if ('define' in window) {
define("discourse/theme-5/discourse/helpers/media-helper", ["discourse-common/lib/helpers"], function (_helpers) {
  "use strict";

  const settings = require("discourse/lib/theme-settings-store").getObjectForTheme(5);
  const themePrefix = key => `theme_translations.5.${key}`;
  (0, _helpers.registerHelper)("media-helper", function (_ref) {
    let [value] = _ref;
    switch (value) {
      case "dark":
        return "all";
      case "light":
        return "none";
      default:
        return "(prefers-color-scheme: dark)";
    }
  });
});
}
