if ('define' in window) {
define("discourse/theme-5/discourse/initializers/color-scheme-toggler", ["exports", "@ember/runloop", "discourse/lib/color-scheme-picker", "discourse/lib/plugin-api", "discourse/lib/theme-selector", "discourse/models/session", "../components/color-scheme-toggler", "../lib/color-scheme-override", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _runloop, _colorSchemePicker, _pluginApi, _themeSelector, _session, _colorSchemeToggler, _colorSchemeOverride, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const settings = require("discourse/lib/theme-settings-store").getObjectForTheme(5);
  const themePrefix = key1 => `theme_translations.5.${key1}`;
  var _default = _exports.default = {
    name: "color-scheme-toggler",
    initialize(container) {
      const keyValueStore = container.lookup("service:key-value-store");
      const storedOverride = keyValueStore.getItem(_colorSchemeOverride.COLOR_SCHEME_OVERRIDE_KEY);
      if (!_session.default.currentProp("darkModeAvailable")) {
        const siteSettings = container.lookup("service:site-settings");
        if (siteSettings.default_dark_mode_color_scheme_id > 0) {
          (0, _colorSchemePicker.loadColorSchemeStylesheet)(siteSettings.default_dark_mode_color_scheme_id, (0, _themeSelector.currentThemeId)(), true).then(() => {
            if (storedOverride) {
              (0, _colorSchemeOverride.colorSchemeOverride)(storedOverride);
            } else {
              // ensures that this extra stylesheet isn't auto-used when OS is in dark mode
              document.querySelector("link#cs-preview-dark").media = "(prefers-color-scheme: none)";
            }
          });
        } else {
          // eslint-disable-next-line no-console
          console.warn("No dark color scheme available, the discourse-color-scheme-toggle component has no effect.");
          return;
        }
      }
      if (storedOverride) {
        _session.default.currentProp("colorSchemeOverride", storedOverride);
      }
      if (_session.default.currentProp("darkModeAvailable") && storedOverride) {
        (0, _runloop.schedule)("afterRender", () => {
          const logoDarkSrc1 = document.querySelector(".title picture source");
          // in some cases the logo widget is not yet rendered
          // so we schedule the calculation after a short delay
          if (!logoDarkSrc1) {
            (0, _runloop.later)(() => (0, _colorSchemeOverride.colorSchemeOverride)(storedOverride), 500);
          } else {
            (0, _colorSchemeOverride.colorSchemeOverride)(storedOverride);
          }
        });
      }
      window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", () => {
        // reset when switching OS dark mode
        keyValueStore.removeItem(_colorSchemeOverride.COLOR_SCHEME_OVERRIDE_KEY);
        _session.default.currentProp("colorSchemeOverride", null);
        (0, _colorSchemeOverride.colorSchemeOverride)();
      });
      if (settings.add_color_scheme_toggle_to_header) {
        (0, _pluginApi.withPluginApi)("1.28.0", api => {
          api.headerIcons.add("header-toggle-button", (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
          /*
            
                      <li class="header-toggle-button header-dropdown-toggle">
                        <span class="header-color-scheme-toggle icon">
                          <ColorSchemeToggler />
                        </span>
                      </li>
                    
          */
          {
            "id": null,
            "block": "[[[1,\"\\n            \"],[10,\"li\"],[14,0,\"header-toggle-button header-dropdown-toggle\"],[12],[1,\"\\n              \"],[10,1],[14,0,\"header-color-scheme-toggle icon\"],[12],[1,\"\\n                \"],[8,[32,0],null,null,null],[1,\"\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n          \"]],[],false,[]]",
            "moduleName": "/discourse/theme-5/discourse/initializers/color-scheme-toggler",
            "scope": () => [_colorSchemeToggler.default],
            "isStrictMode": true
          }), (0, _templateOnly.default)()), {
            before: "search"
          });
        });
      }
    }
  };
});
}
