if ('define' in window) {
define("discourse/theme-5/discourse/components/color-scheme-toggler", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/models/session", "../lib/color-scheme-override", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _session, _colorSchemeOverride, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const settings = require("discourse/lib/theme-settings-store").getObjectForTheme(5);
  const themePrefix = key => `theme_translations.5.${key}`;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DButton
    @action={{action "toggleScheme"}}
    @icon={{this.toggleButtonIcon}}
    @translatedTitle={{theme-i18n "toggle_button_title"}}
    class="color-scheme-toggler btn-flat"
  />
  */
  {
    "id": null,
    "block": "[[[8,[39,0],[[24,0,\"color-scheme-toggler btn-flat\"]],[[\"@action\",\"@icon\",\"@translatedTitle\"],[[28,[37,1],[[30,0],\"toggleScheme\"],null],[30,0,[\"toggleButtonIcon\"]],[28,[37,2],[5,\"toggle_button_title\"],null]]],null]],[],false,[\"d-button\",\"action\",\"theme-i18n\"]]",
    "moduleName": "discourse/components/color-scheme-toggler.hbs",
    "isStrictMode": false
  });
  class ColorSchemeToggler extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "keyValueStore", [_service.service]))();
    #keyValueStore = (() => (dt7948.i(this, "keyValueStore"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "storedOverride", [_tracking.tracked]))();
    #storedOverride = (() => (dt7948.i(this, "storedOverride"), void 0))();
    constructor() {
      super(...arguments);
      this.storedOverride = this.keyValueStore.getItem(_colorSchemeOverride.COLOR_SCHEME_OVERRIDE_KEY);
    }
    get toggleButtonIcon() {
      switch (this.OSMode) {
        case "dark":
          return this.storedOverride === "light" ? "moon" : "sun";
        case "light":
          return this.storedOverride === "dark" ? "sun" : "moon";
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "toggleButtonIcon", [(0, _object.computed)("storedOverride")]))();
    get OSMode() {
      return window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
    }
    toggleScheme() {
      switch (this.OSMode) {
        case "light":
          if (this.keyValueStore.getItem(_colorSchemeOverride.COLOR_SCHEME_OVERRIDE_KEY) === "dark") {
            this.keyValueStore.removeItem(_colorSchemeOverride.COLOR_SCHEME_OVERRIDE_KEY);
          } else {
            this.keyValueStore.setItem(_colorSchemeOverride.COLOR_SCHEME_OVERRIDE_KEY, "dark");
          }
          break;
        case "dark":
          if (this.keyValueStore.getItem(_colorSchemeOverride.COLOR_SCHEME_OVERRIDE_KEY) !== "light") {
            this.keyValueStore.setItem(_colorSchemeOverride.COLOR_SCHEME_OVERRIDE_KEY, "light");
          } else {
            this.keyValueStore.removeItem(_colorSchemeOverride.COLOR_SCHEME_OVERRIDE_KEY);
          }
          break;
      }
      this.storedOverride = this.keyValueStore.getItem(_colorSchemeOverride.COLOR_SCHEME_OVERRIDE_KEY) || null;

      // currently only used to flip category logos back/forth
      _session.default.currentProp("colorSchemeOverride", this.storedOverride);
      (0, _colorSchemeOverride.colorSchemeOverride)(this.storedOverride);
    }
    static #_4 = (() => dt7948.n(this.prototype, "toggleScheme", [_object.action]))();
  }
  _exports.default = ColorSchemeToggler;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ColorSchemeToggler);
});
}
