if ('define' in window) {
define("discourse/theme-5/discourse/connectors/sidebar-footer-actions/toggler-button", ["exports", "discourse/models/session"], function (_exports, _session) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const settings = require("discourse/lib/theme-settings-store").getObjectForTheme(5);
  const themePrefix = key => `theme_translations.5.${key}`;
  var _default = _exports.default = {
    setupComponent(_args, component) {
      component.showInSidebar = false;
      if (!_session.default.currentProp("darkModeAvailable") && component.siteSettings.default_dark_mode_color_scheme_id < 0) {
        return;
      }
      if (!settings.add_color_scheme_toggle_to_header) {
        component.showInSidebar = true;
      }
    }
  };
});
}
